import * as React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import './teacher.css';

export default function Teacher({teachers}) {
    return (
        <div className={'page_block module '+ teachers.class}>
            <h2 className='title-category'>
                {teachers.header}
            </h2>
            {teachers.coaches.map(
                (teacher) => 
                <>
            <div id='image' className='block-img'>
                <img src={teacher.pic} alt="Part 1" />
            </div>
            <div className='teacher-title'>
            {teacher.name}
            </div>
            { teacher.phone && (
            <div className='teacher-phone'>
                <PhoneIcon /> {teacher.phone}   
            </div>
            )}
            {teacher.email && (
            <div className='teacher-email'>
                <EmailIcon /> {teacher.email}
            </div>
            )}
            <div className='teacher-description'>
                {teacher.description}
            </div>
            </>
            )}
        </div>
    )

}