import React, { useState }  from 'react';
import { Container, Grid, TextField, Button } from '@mui/material';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch('https://api.lucia-vr.com/api/contact', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(formData),
          });
          if (response.ok) {
              alert("Message sent successfully!");
              setFormData({ name: '', email: '', subject: '', message: '' });
          } else {
              alert("Failed to send message.");
          }
      } catch (error) {
          console.error("Error:", error);
          alert("An error occurred while sending your message.");
      }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}> {/* Set maximum width here */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="name"
              name="name"
              label="Ihr Name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="email"
              name="email"
              label="Ihre E-Mail-Adresse"
              variant="outlined"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="subject"
              name="subject"
              label="Betreff"
              variant="outlined"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="message"
              name="message"
              label="Ihre Nachricht"
              variant="outlined"
              multiline
              rows={6}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" type="submit">
              Senden
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Contact;
