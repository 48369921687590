import { addDays, addWeeks, addMonths, isBefore } from "date-fns";

// Recurrence expansion function
export default function expandRecurringEvents(events) {
    const expandedEvents = [];

    events.forEach(event => {
        if (event.recurrent) {
            let currentStart = new Date(event.start);
            let currentEnd = new Date(event.end);

            while (isBefore(currentStart, new Date(event.recurrenceEnd))) {
                expandedEvents.push({
                    ...event,
                    start: new Date(currentStart),
                    end: new Date(currentEnd),
                });

                // Generate the next occurrence based on recurrence type
                if (event.recurrenceType === "daily") {
                    currentStart = addDays(currentStart, 1);
                    currentEnd = addDays(currentEnd, 1);
                } else if (event.recurrenceType === "weekly") {
                    currentStart = addWeeks(currentStart, 1);
                    currentEnd = addWeeks(currentEnd, 1);
                } else if (event.recurrenceType === "monthly") {
                    currentStart = addMonths(currentStart, 1);
                    currentEnd = addMonths(currentEnd, 1);
                } else {
                    break;  // Stop if recurrence type is unsupported
                }
            }
        } else {
            // Non-recurring event; add as-is
            expandedEvents.push(event);
        }
    });

    return expandedEvents;
}
