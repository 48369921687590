import React from 'react';
import { Typography, Box, Link, Divider, Container } from '@mui/material';
import { styled } from '@mui/system';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SampleImage from '../../src/static/images/Header_svaach.jpg'; // Replace with your image path

const PageContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: theme.spacing(4),
	height: '100vh',
	[theme.breakpoints.down('sm')]: {
	  flexDirection: 'column',  // Stack items on smaller screens
	  alignItems: 'center',
	  paddingTop: theme.spacing(8),
	},
  }));
  
  const TitleContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	textAlign: 'left',
	gap: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
	  alignItems: 'center',  // Center-align on smaller screens
	  textAlign: 'center',
	},
  }));
  
  const ImageContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '500px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	[theme.breakpoints.up('md')]: {
	  maxWidth: '500px', // Larger image for large screens
	},
	[theme.breakpoints.down('sm')]: {
	  maxWidth: '100%',
	  marginTop: theme.spacing(4),
	},
  }));
  
  const StyledImage = styled('img')({
	width: '100%',
	height: 'auto',
	borderRadius: '8px',
  });
  
  const LinksContainer = styled(Box)(({ theme }) => ({
	marginTop: theme.spacing(3),
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(1),
  }));
  
  const AboutPage = () => {
	return (
		<div className="gradient-background">		
		<PageContainer maxWidth="lg">
			{/* Left Section: Title, Subtitle */}
			<Box>
			<TitleContainer>
				<Typography variant="h2" color="primary" fontWeight="bold">
				Herzlich Willkommen
				</Typography>
				<Typography variant="h5" color="textSecondary">
				Willkommen auf der Vereinsseite des SV Aach. Hier finden Sie Informationen über aktuelle Kurse und vieles mehr.
				</Typography>
			</TitleContainer>
			
			{/* Right Section: Links */}
			<LinksContainer>
				<Link href="sport" variant="body1" underline="hover" display="flex" alignItems="center" gap={1}>
				<SportsBasketballIcon color="primary" />
				Sport
				</Link>
				<Link href="contact" variant="body1" underline="hover" display="flex" alignItems="center" gap={1}>
				<ContactMailIcon color="primary" />
				Kontakt
				</Link>
			</LinksContainer>
			</Box>
	
			{/* Image Section */}
			<ImageContainer>
			<StyledImage src={SampleImage} alt="Sample" />
			</ImageContainer>
		</PageContainer>
	</div>
	);
  };
  
  export default AboutPage;