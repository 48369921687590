import React, { useState, useEffect } from "react";
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import {
	Switch,
	Redirect,
	Link,
} from "react-router-dom";
	  
import Home from './pages';
import About from './pages/about';
import News from './pages/news';
import Contact from './pages/contact';
import Sport from './pages/sport';
import List from './pages/list';
import Register from './pages/register';
import Login from './pages/login';
import Logout from './pages/logout';

import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "js-cookie";
import NavbarPerso from "./components/Navbar/index";
import LoginPage from "./pages/LoginPage";
import NavbarLoggedOut from "./components/Navbar/indexlogout";
import Profile from "./pages/profile";
import Calendar from "./pages/calendar";
import MyStaticCalendar from "./pages/newcalendar";
import Admin from "./pages/admin";
import SeniorGym from "./components/seniorgym";
import Bodyshape from "./components/bodyshape";
import Football from "./components/Football";

import firebase  from './services/firebase';
import Unauthorized from "./pages/unauthorized";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Kinderturnen from "./components/kinderturnen";

const theme = createTheme(
    {
        palette: {
          primary: {
            main: '#181719',
          },
          secondary: {
            main: '#ce4000',
          },
        },
      }
  );

function App() {
	const [user, setUser] = useState(null);

	useEffect(() => {
	  firebase.auth().onAuthStateChanged(user => {
		setUser(user);
	  })
	}, [])
  
	return (
		<ThemeProvider theme={theme}>

			<Router>
				{user ? (
						<NavbarPerso user={user} /> ) :
					(
						<NavbarLoggedOut /> 
					)
				}
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					{/* <Route path='/calendar' element={<Calendar />} /> */}
					<Route path='/calendar' element={<MyStaticCalendar />} />
					<Route path='/contact' element={<Contact />} />
					{user ? (
						<Route path='/news' element={<News />} />
					) : (
						<Route path='/news' element={<LoginPage />} />
					)}
					<Route path='/list' element={<List />} />
					<Route path='/sports' element={<Sport />} />
					<Route path="/sports/foot" element={<Football />} />
					<Route path="/sports/kinderturnen" element={<Kinderturnen />} />
					<Route path="/sports/bodyshape" element={<Bodyshape />} />
					<Route path="/sports/seniorgym" element={<SeniorGym />} />
					<Route path='/list' element={<List />} />
					{user ? (
						<Route path='/profile' element={<Profile user={user} />} />
					) : (
						<Route path='/profile' element={<LoginPage />} />
					)}
					{user ? (
						user.email === "verdon.nicolas@gmail.com" ? (
							<Route path='/admin' element={<Admin />} />
						) : (
							<Route path='/admin' element={<Unauthorized />} />
						)
					) : (
						<Route path='/admin' element={<Unauthorized />} />
					)}
					<Route path='/login' element={<LoginPage />} />
					<Route path='/logout' element={<Logout />} />
				</Routes>
			</Router>
		</ThemeProvider>

	);
}

export default App;
