import * as React from 'react';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material';
import WhatsAppShareButton from '../Share/WhatsApp.js';

const HoverableBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
      backgroundColor: '#f1f3f4',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

export default function Waltraud() {
  return (
    <div>
      <hr></hr>
      <h2 className='teacher-additional'>
      Trainer für:
      </h2>
      <Typography variant="body2" color="text.secondary">
      <a href='/sports/kinderturnen'>
        <HoverableBox>
        <p className='links-sport'>
          Kinderturnen
        </p> 
      </HoverableBox>
      </a>
      <a href='/sports/seniorgym'>
        <HoverableBox>
        <p className='links-sport'>
        Seniorengymnastik
        </p> 
      </HoverableBox>
      </a>
      </Typography>
      <hr></hr>
      <h2 className='teacher-additional'>
        Seite Teilen
        <WhatsAppShareButton />
      </h2>
    </div>
  );
}