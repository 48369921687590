import axios from 'axios';

const backendApi = axios.create({
  baseURL: 'http://localhost:8000',  // Replace with your backend server URL
});

export const getItems = async () => {
  const response = await backendApi.get('/api/database/items');
  console.log("on lit les items");
  return response.data;
};
