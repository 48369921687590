import * as React from 'react';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppShareButton from '../Share/WhatsApp.js';

const HoverableBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
      backgroundColor: '#f1f3f4',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

export default function Heiko() {
  return (
    <div>
      {/* <hr></hr> */}
      <a href='/contact'>
        <HoverableBox>
        <p className='links-sport'>
          Kontakt
        </p> 
      </HoverableBox>
      </a>
      <hr></hr>
      <Typography variant="body2" color="text.secondary">
        <p className='links-sport'>
          <a href='https://www.facebook.com/sgabnofficial'><FacebookIcon /> SG Aach/Butzweiler/Newel</a>
        </p> 
      </Typography>
        Teilen mit WhatsApp
        <WhatsAppShareButton />
    </div>
  );
}