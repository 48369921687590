import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box  } from '@mui/material';
import './calendar.css'
import expandRecurringEvents from '../utils/RecurrenceEvents'

const localizer = momentLocalizer(moment);

const MyStaticCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Track selected event for dialog display
  const [openDialog, setOpenDialog] = useState(false);      // Track dialog visibility

  useEffect(() => {
    // Fetch events from FastAPI backend
    axios.get('https://api.lucia-vr.com/api/calendar/events')
      .then(response => {
        const expandedEvents = expandRecurringEvents(response.data);
        setEvents(expandedEvents)
    })
      .catch(error => console.error("Error fetching events:", error));
  }, []);

  // Function to handle event click
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);  // Set the clicked event as selected
    setOpenDialog(true);       // Show the dialog
  };

  // Function to close dialog
  const handleCloseDialog = () => setOpenDialog(false);

  // Function to customize event colors
  const eventStyleGetter = (event) => {
    let backgroundColor;
    
    // Customize color based on event type
    switch (event.type) {
      case 'foot':
        backgroundColor = '#4caf50'; // green
        break;
      case 'turnen':
        backgroundColor = '#f44336'; // red
        break;
      case 'frauen':
        backgroundColor = '#2196f3'; // blue
        break;
      case 'senior':
        backgroundColor = 'darkgoldenrod '; // blue
        break;
      case 'exceptional':
        backgroundColor = 'darksalmon'; // blue
        break;
      default:
        backgroundColor = '#9e9e9e'; // gray for other events
    }

    return {
      style: {
        backgroundColor,
        color: 'white',    // Set text color for readability
        borderRadius: '5px',
        border: 'none',
        paddingLeft: '5px'
      }
    };
  };


  return (
    <div style={{ height: '80vh',  width: '80%', margin: '0 auto', padding: '20px'}}>
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleSelectEvent}  // Attach event handler
            style={{ height: '100%' }}
            eventPropGetter={eventStyleGetter}
            defaultView="month"
            views={['month']} // Ensure all views are specified      
        />

      {/* Dialog for displaying event details */}
      <Dialog 
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
            sx: {
              width: '80%',        // Width of the modal as 80% of the viewport
              maxWidth: '600px',   // Maximum width
              minWidth: '300px',   // Minimum width
            },
          }}
        >
        <DialogTitle 
          sx={{
            backgroundColor:
              selectedEvent?.type === 'foot'
                ? '#4caf50'
                : selectedEvent?.type === 'turnen'
                ? '#f44336'
                : selectedEvent?.type === 'frauen'
                ? '#2196f3'
                : selectedEvent?.type === 'senior'
                ? 'darkgoldenrod '
                : selectedEvent?.type === 'exceptional'
                ? 'darksalmon'
                : '#9e9e9e',
            color: 'white'
          }}>
            {selectedEvent?.title}</DialogTitle>
        <DialogContent style={{ paddingTop: '16px' }}>
          <Typography variant="body1">
            <strong>Start:</strong> {moment(selectedEvent?.start).format("HH:mm")}
          </Typography>
          <Typography variant="body1">
            <strong>End:</strong> {moment(selectedEvent?.end).format("HH:mm")}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ paddingTop: '8px' }}>
            <strong>Description:</strong> {selectedEvent?.description || "No description provided."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyStaticCalendar;
