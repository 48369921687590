import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick"; // Import Slider from react-slick
import Aach from '../../src/static/images/Aach.jpg';
import SV01 from '../../src/static/images/SVaachBuilding01.jpg';
import SV02 from '../../src/static/images/SVaachBuilding02.jpg';
import './About.css';
// Import carousel CSS
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <div
    className="slick-next"
    onClick={onClick}
    style={{ border: "2px solid red" }} // Temporary debug border
  >
    ➡️
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="slick-prev"
    onClick={onClick}
    style={{ border: "2px solid red" }} // Temporary debug border
  >
    ⬅️
  </div>
);

const About = () => {
  // Define the animation for the cards
  const cardVariants = {
    offscreen: {
      y: 200,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1.2
      }
    }
  };

  // Sample images for the slideshow
  const images = [
    SV01,
    SV02,
    SV01
  ];

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  // Data for cards
  const food = [
    ["Willkommen", "Willkommen auf der Vereinsseite des SV Aach. Hier finden Sie Informationen über aktuelle Kurse und vieles mehr.", Aach],
    ["SV Aach", "Explications 2"],
    ["Vorstand", "Foto.."]
  ];

  return (
    <div className="gradient-background">
      {food.map(([emoji, description, pic], index) => (
        <motion.div
          key={index}
          className="card-container"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.3 }}
          variants={cardVariants}
          transition={{ delay: index * 0.3 }}
        >
          <div className="card-content">
            <h2>{emoji}</h2>
            <p>{description}</p>

            {/* Conditionally render the slideshow for the third card */}
            {index === 1 ? (
              <Slider {...sliderSettings} className="slider-container">
                {images.map((img, i) => (
                  <div key={i}>
                    <img src={img} alt={`Slide ${i + 1}`} className="slider-image" />
                  </div>
                ))}
              </Slider>
            ) : (
               pic &&
                <div className="img-container">
                <img src={Aach} alt="Aach" />
              </div>
            )}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default About;
