import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import Alert  from 'react-bootstrap/Alert';
import axios from 'axios';

const NewsForm = () => {
  // State variables for the form inputs
  const [title, setTitle] = useState('');
//   const [description, setDescription] = useState(['']);
  const [image, setImage] = useState(null);
  const [success, setSuccess] = useState(null);
  const [items, setItems] = useState([{id: 1, value: ''}]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const paragraphs = [];
    console.log(items);

    items.map((item) => {
        paragraphs.push(item.value)
        paragraphs.push("|")
    })
    console.log(paragraphs);
    // paragraphs.push(description);
    // paragraphs.push("|");
    // paragraphs.push("yo");
    // paragraphs.push("|");
    // paragraphs.push("zob");


    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', paragraphs);
    formData.append('images', image, image.name);


    try {
        const response = await axios.post('http://localhost:8000/api/news/posts/add', formData,
        {
            headers: {
              'Content-Type': 'multipart/form-data;charset=UTF-8', // Important for sending files
            },
        }
        
        );

        setSuccess(null);
        setTitle('');
        setItems([{id: 1, value: ''}]);
        setImage(null);
        setSuccess("New blog item added!")

    } catch (error) {
        console.error(error);
    }

  };

  const addItem = () => {
    const itemList = [...items];
    console.log(itemList);
    itemList.push({id: itemList.length+1, value: ''})
    setItems(itemList)
  }

  const handleInputChange = (id, value) => {
    const updatedInputFields = items.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setItems(updatedInputFields);
  };


  return (
    <>
    <Card>
      <Card.Body>
        <Card.Title>Form Example</Card.Title>
        <Form onSubmit={handleSubmit}>
        {success && <Alert variant="success">{success}</Alert>}
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <br></br>
          {
            items.map((item) => (
                <>
                    <Form.Group controlId={item.id}>
                        <Form.Label>Paragraph {item.id}</Form.Label>
                        <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter description"
                        value={item.value}
                        onChange={(e) => handleInputChange(item.id, e.target.value)}
                        />
                    </Form.Group>
                </>
            ))
          }
          <Button className='button-form' variant='secondary' onClick={addItem}>Add paragraph</Button>

          <Form.Group controlId="formImage">
            <Form.Label>Image</Form.Label>
            <Form.Control type="file" 
              id="imageUpload"
              label="Choose an image"
              custom
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>

          <Button className='button-form' variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
    </>
  );
};

export default NewsForm;
